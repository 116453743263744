<template>
  <el-dialog
    title="Change Customer Status"
    :visible.sync="shouldShow"
    append-to-body
    width="35%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            label="Select status"
            prop="subscriber_status"
            :rules="validateField()"
          >
            <el-radio-group v-model="form.subscriber_status">
              <el-radio label="subscriber">Subscriber</el-radio>
              <el-radio label="paused">Paused</el-radio>
              <el-radio label="churned">Churned</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="10" class="is-flex-wrap">
        <el-col :span="24">
          <el-form-item
            label="Start date"
            prop="start_date"
            :rules="validateField()"
          >
            <el-date-picker
              v-model="form.start_date"
              type="date"
              value-format="yyyy-MM-dd"
              format="dd MMM, yyyy"
              :picker-options="{
                disabledDate: disabledDates,
              }"
              prefix-icon="eden-icon--calendar"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <p>
            <span class="text-grey-primary text-bold">Note:</span><br />
            Status does not change until the specified start date. All orders
            would be
            <span class="text-bold text-grey-primary">{{
              form.subscriber_status === "subscriber" ? "unpaused" : "paused"
            }}</span>
            from the specified start date
            <span class="text-bold text-grey-primary">{{
              form.start_date ? formatDate(form.start_date, "mo d, y") : "-"
            }}</span>
          </p>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button type="primary" :loading="loading" @click="change"
        >Change status</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import customer from "@/requests/customers/customer/actions";

export default {
  name: "CustomerStatusChange",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        subscriber_status: "",
        start_date: null,
      },
      loading: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.subscriber_status = this.customer.customer_status;
      }
    },
  },
  methods: {
    closeEvent() {
      this.$refs.form.resetFields();
      this.shouldShow = false;
    },
    disabledDates(time) {
      if (
        this.allowAccessFor(["admin"]) ||
        this.allowAccessFor(["gardener_pro"])
      ) {
        return false;
      }

      const currentTime = new Date().getHours();
      const currentDate = this.formatDate(new Date(), "y-m-d");
      const nextDay = this.getFutureDate({
        date: new Date(),
        unit: "day",
        num: 1,
        format: "y-m-d",
      });
      const timeDate = this.formatDate(time, "y-m-d");

      const today = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000);
      if (time.getTime() < today) {
        return true;
      }

      if (timeDate === currentDate) {
        return true;
      }

      if (timeDate === nextDay && currentTime >= 14) {
        return true;
      }
    },
    change() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        const { subscriber_status, start_date } = this.form;
        const today = this.formatDate(new Date(), "y-m-d");

        const payload = {
          subscriber_status,
          start_date: start_date === today ? null : start_date,
        };
        this.loading = true;

        customer
          .status(this.customer.id, payload)
          .then((response) => {
            if (response.data.status) {
              this.loading = false;
              this.$message.success({
                message: response.data.message,
                duration: 5000,
              });

              this.$emit("success", {
                customer_status:
                  start_date <= today
                    ? subscriber_status
                    : this.customer.status,
              });

              this.closeEvent();
            }
          })
          .catch((error) => {
            this.loading = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
