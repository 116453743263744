import { render, staticRenderFns } from "./CustomerStatusChange.vue?vue&type=template&id=926b5c36&scoped=true&"
import script from "./CustomerStatusChange.vue?vue&type=script&lang=js&"
export * from "./CustomerStatusChange.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "926b5c36",
  null
  
)

export default component.exports